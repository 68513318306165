import { useDataBounds } from '@/hooks/useDataBounds'
import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useKeyCloak } from '@/hooks/useKeyCloak'
import { useLiveStreamSetup } from '@/hooks/useLiveStreamSetup'
import { usePermissions } from '@/hooks/usePermissions'
import { useRouter } from 'next/compat/router'
import { BiTrendingDown } from 'react-icons/bi'
import { HiOutlineChevronLeft, HiOutlineChevronRight } from 'react-icons/hi'
import { MdFormatListBulleted } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Flex, Grid, Icon, IconButton, Square } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { EmergencyCall, Gateway, Logo, Rule } from '@/components/icons'
import {
  CaseManagement,
  Dashboard,
  Facility,
  Globe,
  GuardTour,
  Incident,
  Logout,
  SecurityDevice,
  Settings,
} from '@/components/icons'
import { useDevicesFilter } from '@/features/devices-table'
import { useFacilitiesFilter } from '@/features/facilities-table'
import {
  useDevicesLazyQuery,
  useFacilitiesLazyQuery,
  useOrganizationLazyQuery,
} from '@/graphql/generated/hooks'
import { selectFullName } from '@/redux/me/meSlice'
import { selectSideNavOpen, toggleSideNavOpen } from '@/redux/ui/uiSlice'
import { SIDE_BAR_WIDTH_CLOSED, SIDE_BAR_WIDTH_OPEN } from '@/styles/theme'
import { mixpanel } from '@/utils/analytics'

import { SideBarEditMode } from './SideBarEditMode'
import { SideBarHelp } from './SideBarHelp'
import { SideBarNavItem } from './SideBarNavItem'

const SideBarStyles = styled.div<{ $show: boolean }>`
  top: 0;
  left: 0;
  width: ${(p) => (p.$show ? SIDE_BAR_WIDTH_OPEN : SIDE_BAR_WIDTH_CLOSED)};
  background: linear-gradient(180deg, #16357f 0%, #091848 100%);
  box-shadow: 4px 0 14px 0 rgba(65, 71, 95, 0.17);
  transition: ease-in-out 0.3s width;
  z-index: 100;
  height: 100vh;
`

const NavToggle = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 30px;
  transform: translate(50%, -50%);
  background-color: #fff;
  font-size: 20px;
  height: 18px;
  min-width: 18px;
`

export const SideBar = () => {
  const { filters: facilitiesFilters } = useFacilitiesFilter()
  const { filters: devicesFilters } = useDevicesFilter()
  const { bounds } = useDataBounds()
  const [getFacilities] = useFacilitiesLazyQuery({
    variables: {
      filter: facilitiesFilters,
    },
  })
  const [getDevices] = useDevicesLazyQuery({
    variables: {
      filter: devicesFilters,
    },
  })
  const [getOrganization] = useOrganizationLazyQuery({
    variables: { box: bounds },
  })
  const dispatch = useDispatch()
  const { logOut } = useKeyCloak()

  const fullName = useSelector(selectFullName)
  const isSideNavOpen = useSelector(selectSideNavOpen)
  const router = useRouter()
  const {
    shouldShowDashboardPage,
    shouldShowEditMode,
    shouldShowSOPPage,
    shouldShowIncidentRulesPage,
    shouldShowCaseManagementPage,
  } = usePermissions()

  const flags = useFeatureFlags()
  const { hasNoLiveStream, isOrangePeel } = useLiveStreamSetup()
  const shouldShowLegacyDashboard = flags[FeatureFlag.legacyDashboard]
  const isEmergencyCallEnabled = flags[FeatureFlag.emergencyCall]
  const isGuardToursEnabled = flags[FeatureFlag.guardTours]
  const isCaseManagementEnabled = flags[FeatureFlag.caseManagement]
  const isIncidentRulesEnabled = flags[FeatureFlag.incidentRules]

  return (
    <SideBarStyles $show={isSideNavOpen}>
      <Flex
        as='nav'
        direction='column'
        h='100vh'
        justifyContent='space-between'
        position='relative'
      >
        <NavToggle
          aria-label='Toggle Sidebar'
          borderRadius='full'
          data-testid='sidebar_expand_toggle'
          icon={
            <Icon
              as={isSideNavOpen ? HiOutlineChevronLeft : HiOutlineChevronRight}
              boxSize='16px'
              color='#4C535F'
            />
          }
          onClick={() => dispatch(toggleSideNavOpen())}
          size='xs'
        />
        <Grid h='full' templateRows='60px auto'>
          <Box>
            <Grid
              alignItems='center'
              cursor='pointer'
              gap='5px'
              onClick={() => router.push('/global')}
              overflow='hidden'
              templateColumns='max-content max-content'
            >
              <Square h='60px' w='60px'>
                <Icon as={Logo} boxSize='34px' />
              </Square>
              {isSideNavOpen && (
                <Box
                  color='#fff'
                  fontSize='16px'
                  fontWeight='bold'
                  letterSpacing='-0.65px'
                  lineHeight='20px'
                >
                  HiveWatch
                </Box>
              )}
            </Grid>
          </Box>
          <Flex
            css={{
              '&::-webkit-scrollbar': {
                display: 'none',
                width: 0,
              },
            }}
            flexDirection='column'
            justifyContent='space-between'
            mb='60px'
            overflow='scroll'
            pt='20px'
          >
            <Box>
              <SideBarNavItem
                dataTestId='sidebar_navItem_global'
                icon={Globe}
                label='Global'
                onMouseOver={getOrganization}
                path='/global'
              />
              <SideBarNavItem
                dataTestId='sidebar_navItem_facilities'
                icon={Facility}
                label='Facilities'
                onMouseOver={getFacilities}
                path='/facilities'
              />
              <SideBarNavItem
                dataTestId='sidebar_navItem_incidents'
                icon={Incident}
                label='Incidents'
                path='/incidents'
              />
              {!hasNoLiveStream && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_devices'
                  icon={SecurityDevice}
                  label='Devices'
                  onMouseOver={getDevices}
                  path='/devices'
                />
              )}
              {shouldShowDashboardPage &&
                (shouldShowLegacyDashboard ? (
                  <SideBarNavItem
                    dataTestId='sidebar_navItem_metrics'
                    icon={Dashboard}
                    label='Metrics'
                    path='/metrics-dashboard'
                  />
                ) : (
                  <SideBarNavItem
                    dataTestId='sidebar_navItem_dashboard'
                    icon={BiTrendingDown}
                    label='Dashboard'
                    path='/dashboard'
                  />
                ))}
              {shouldShowSOPPage && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_sop'
                  icon={MdFormatListBulleted}
                  label='SOPs'
                  path='/standard-operating-procedures'
                />
              )}
              {isEmergencyCallEnabled && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_emergencyCalling'
                  icon={EmergencyCall}
                  label='Emergency Calls'
                  path='/emergency-call-log'
                />
              )}
              {isGuardToursEnabled && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_submittedTours'
                  icon={GuardTour}
                  label='Submitted Tours'
                  onClick={() =>
                    mixpanel.track('Button Press: Nav - Submitted Tours List')
                  }
                  path='/submitted-tours'
                />
              )}
              {isOrangePeel && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_gateways'
                  icon={Gateway}
                  label='Gateways'
                  path='/gateways'
                />
              )}
              {isCaseManagementEnabled && shouldShowCaseManagementPage && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_case_management'
                  icon={CaseManagement}
                  label='Case Management'
                  path='/case-management'
                />
              )}
              {isIncidentRulesEnabled && shouldShowIncidentRulesPage && (
                <SideBarNavItem
                  dataTestId='sidebar_navItem_incidentRules'
                  icon={Rule}
                  iconSize='32px'
                  label='Rules'
                  path='/incident-rules'
                />
              )}
            </Box>
            <Box>
              {shouldShowEditMode && <SideBarEditMode />}
              <SideBarHelp />
              <SideBarNavItem
                dataTestId='sidebar_navItem_settings'
                icon={Settings}
                label='Settings'
                path='/settings/account-management'
              />

              <SideBarNavItem
                avatarName={fullName}
                dataTestId='sidebar_navItem_me'
                label={fullName}
                subLabel='LOGGED IN AS'
              />
            </Box>
          </Flex>
          <Box bottom={0} pos='absolute'>
            <SideBarNavItem
              dataTestId='sidebar_navItem_logout'
              icon={Logout}
              label='Logout'
              onClick={logOut}
            />
          </Box>
        </Grid>
      </Flex>
    </SideBarStyles>
  )
}
